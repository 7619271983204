.recharts-default-legend {
  visibility: hidden;
}

.MuiChartsLegend-root {
  visibility: hidden;
}

.recharts-layer .recharts-bar-rectangle {
  background: linear-gradient(71.48deg, #ff9f0a 0%, #ff375f 62.9%);
}

.css-1vuxth3-MuiBarElement-root {
  fill: url(#myGradient) !important;
}

.gradientStyle {
  background: "linear-gradient(71.48deg, #FF9F0A 0%, #FF375F 62.9%)";
}

.recharts-cartesian-grid {
  visibility: hidden;
}

.recharts-cartesian-grid-horizontal {
  visibility: hidden;
}

.recharts-cartesian-grid-vertical {
  visibility: hidden;
}

.recharts-default-legend {
  visibility: hidden;
}

.MuiChartsLegend-root {
  visibility: hidden;
}

.recharts-layer .recharts-bar-rectangle {
  background: linear-gradient(71.48deg, #ff9f0a 0%, #ff375f 62.9%);
}

.css-1vuxth3-MuiBarElement-root {
  fill: url(#myGradient) !important;
}

.gradientStyle {
  background: "linear-gradient(71.48deg, #FF9F0A 0%, #FF375F 62.9%)";
}

.recharts-cartesian-grid {
  visibility: hidden;
}

.recharts-cartesian-grid-horizontal {
  visibility: hidden;
}

.recharts-cartesian-grid-vertical {
  visibility: hidden;
}

/* @media (max-width: 768px) {
  .recharts-cartesian-axis-ticks {
    transform: rotate(-45deg);
    transform-origin: center;
  }
} */


/* @media (max-width: 774px) {
.recharts-layer .recharts-cartesian-axis .recharts-xAxis .xAxis .recharts-cartesian-axis-ticks{
  transform: rotate(-45deg);
  transform-origin: center;
  /* flex-direction: column; */
/* }
} */ 



/* .recharts-surface{
  width: 774px;
  overflow-x: scroll;
} */

/* @media only screen and (max-width: 767px) {
  .max-mobile {
    max-width: 600px; 
    overflow-x: scroll;
  }
} */

/* @media only screen and (max-width: 767px) {
  .max-mobile{
    width: 900px;
    overflow-x: scroll;
  }
  
} */

/* .recharts-responsive-container{
  width: 425px;
  overflow-x: scroll;
  
} */

/* .recharts-surface{
  width: 425px;
  overflow-x: scroll;
} */

@media only screen and (max-width: 767px){
.recharts-responsive-container{
  width:600px !important;
  
}
}