.stepper-box .stepper-div .MuiStep-horizontal .MuiStepLabel-horizontal {
  flex-direction: row-reverse;
  column-gap: 10px;
}

.stepper-box .step-div {
  margin-left: 10px;
}

.stepper-box .steps-counter-div {
  display: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1800px) {
  .stepper-box .stepper-div,
  .stepper-box .stepper-div .Mui-disabled .MuiStepLabel-label,
  .stepper-box span.MuiStepLabel-label {
    font-size: 1.05rem !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1365px) {
  .stepper-box .stepper-div,
  .stepper-box .stepper-div .Mui-disabled .MuiStepLabel-label,
  .stepper-box span.MuiStepLabel-label {
    font-size: 1.1rem !important;
  }

  /* Pending */

  .stepper-box .stepper-div svg {
    width: 16px;
    height: 16px;
    padding: 3px !important;
  }

  .stepper-box .stepper-div .MuiStep-horizontal .MuiStepLabel-horizontal {
    column-gap: 4px;
    padding: 0px 5px;
  }

  .stepper-box .step-div {
    margin-left: 0px;
  }

  /* Pending */
  .stepper-box .stepper-box span.MuiStepLabel-iconContainer.Mui-active,
  .stepper-box span.MuiStepLabel-iconContainer.Mui-disabled,
  .stepper-box span.MuiStepLabel-iconContainer.Mui-completed {
    padding-right: 0px;
  }

  .stepper-box .MuiStep-root.MuiStep-horizontal {
    padding: 0px;
  }
}

@media only screen and (max-width: 751px) {
  .stepper-box .steps-counter-div {
    display: flex;
    justify-content: center;
  }

  .stepper-box .steps-counter-div span {
    font-family: "Satoshi";
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  .stepper-box .step-div svg text.MuiStepIcon-text {
    fill: rgba(0, 0, 0, 0.8) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.95rem !important;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .stepper-box .steps-counter-div svg text.MuiStepIcon-text,
  .stepper-box .Mui-active .MuiStepIcon-root .MuiStepIcon-text {
    fill: #06b217 !important;
  }

  .stepper-box span.MuiStepLabel-label.Mui-active,
  .stepper-box span.MuiStepLabel-label.Mui-disabled,
  .stepper-box span.MuiStepLabel-label.Mui-completed {
    display: none;
  }

  .stepper-box .steps-counter-div {
    margin-bottom: 18px;
  }
  .stepper-box .MuiStepConnector-lineHorizontal {
    border-top-style: dotted !important;
    border-top-width: 7px !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
  }

  .stepper-box span.MuiStepLabel-root.MuiStepLabel-horizontal {
    column-gap: 0px !important;
  }

  .stepper-box .MuiStep-root.MuiStep-horizontal {
    margin-left: 0px;
  }

  .stepper-box .step-div,
  .stepper-box .MuiStepLabel-iconContainer.Mui-active,
  .stepper-box .MuiStepLabel-iconContainer {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .stepper-box .MuiStepper-root.MuiStepper-horizontal {
    width: 225px;
    margin: auto;
  }
}
