/* .account-settings-div form div {
  padding: 12px 20px;
  margin-bottom: 0px;

  border-radius: 5px;
} */

.account-settings-div form .country-select {
  padding: 8px 20px;
}

.account-settings-div form .country-select div {
  margin-bottom: 0px;
}

.account-settings-div .country-select-div,
.account-settings-div .country-select-div div {
  padding: 0 !important;
}

.account-settings-div .country-select-div input {
  padding: 18px 20px !important;
}

.account-settings-div #react-flag-select button {
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

.account-tab,
.password-tab {
  font-family: "Satoshi";
  font-size: 20px;
  font-weight: 500 !important;
  color: black !important;
  text-transform: none !important;
}

.account-settings-div .account-tab.Mui-selected,
.account-settings-div .password-tab.Mui-selected {
  background-color: #fff6f5;
  font-weight: 500 !important;
  color: black !important;
  text-transform: none;
}

.account-settings-div .MuiTabs-indicator {
  background: linear-gradient(71.48deg, #ff9f0a 0%, #ff375f 62.9%),
    linear-gradient(0deg, #fff6f5, #fff6f5);
}

.account-settings-div .MuiTabPanel-root {
  padding-left: 0;
  padding-right: 0;
}
