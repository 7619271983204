#filter-location .extra-filter-category{
    display: none;
}


#filter-location .extra-filter-options svg{
    background-image: url(../../../assets/ColorfulDownArrow.png);
    background-size: contain;
    background-repeat: no-repeat;

    
}