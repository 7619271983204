@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/satoshi");
@import url("https://unpkg.com/leaflet@1.9.3/dist/leaflet.css");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "FuturaMedium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #fef0d2bf;
  --sec: #ff832b;
  --sec-ligh: #f9aa56;
  --dark: #dfdfdf;
  --light: #efefef;
  --blue: rgba(43, 51, 231, 1);
}

.container {
  max-width: 1920px;
  margin: auto;
}

.gradient-button::after {
  background: linear-gradient(to right, #ff9f0a 0%, #ff375f 62.9%);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}

.main-container {
  min-height: calc(100vh - 120px);
  margin: 148px 0px 20px 270px;
  padding: 0em 1em 0em 2.2em;
}

.ql-toolbar.ql-snow {
  background: #eaecf0;
}

.quill {
  height: 100%;
}

.ql-container {
  height: 100% !important;
}

.ql-editor {
  height: 100% !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
}

@media screen and (max-width: 900px) {
  .main-container {
    height: calc(100% - 210px);
    width: 100%;
    margin: 130px 0px 80px 0px;
    padding: 0em 0.4em !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(228, 231, 236);
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}

.normal-table {
  border: 1px solid #d0e2ff;
}

.normal-table th {
  padding: 8px;
}

.normal-table > tbody > tr > td {
  padding: 8px;
  border: 1px solid #d0e2ff;
}

.sort-container {
  display: "inline-block";
  margin-left: 5px;
  height: fit-content;
  position: absolute;
  right: 20px;
  top: 50;
  transform: translateX(50%);
  z-index: 1;
}

.sort-icon {
  cursor: pointer;
  color: #f58220;
  height: 17px !important;
  width: 17px !important;
}

.css-n7tliy-MuiStep-root {
  padding-bottom: 1rem !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: "red" !important;
}

.drop-zone {
  display: flex;
  width: 567px;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: var(--Spacing-12, 12px);
  border: 2px dashed #36c;

  background: #f7faff;
}

.Upload_field {
  border-radius: 4px;

  border-left: 1px solid #dcdfe3;
  border-top: 1px solid #dcdfe3;
  border-bottom: 1px solid #dcdfe3;
}

.Upload_field:has(.Mui-focused) {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid black;
  height: 74px;
}

.scrollbar {
  display: none;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: red !important;
}

.swiper-button-next::after {
  color: white;
  width: 48px;
  height: 48px;
  backdrop-filter: blur(20px);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.18) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border-radius: 50%;
  padding: 40px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 200px;
}

.swiper-button-prev {
  color: black;
  width: 50px;
  margin-left: 20px;
}

.swiper-button-prev.swiper-button-disabled {
  color: black;
}

.swiper-button-prev:after {
  color: black;
  font-size: 30%;
}

.reactflagsselect-module_selectbtn__19ww7 {
  padding: 50px;
}

:root {
  --swiper-navigation-size: 30px;
}

.swiper-button-next:after {
  font-size: 10px;
}

.swiper-button-prev:after {
  font-size: 10px;
}

#react-flag-select button {
  border: 2px solid var(--Linear-BG, #ff9f0a);
  border-image: linear-gradient(#ff9f0a, red) 20;
  border-style: solid;
  border-radius: 5px;
  height: 60px;
}

.slick-prev,
.slick-next {
  width: 48px;
  height: 48px;
}

.slick-next {
  margin-left: 50px;
  right: -60px;
  top: 260px;
}

.breadcrumbs_title > ol > .MuiBreadcrumbs-li:last-child {
  color: #3366cc;
  text-decoration: underline;
}

.one-row-table {
  border-collapse: collapse;
  width: 100%;
}

.one-row-table td {
  border: 0.5px solid gray;
  height: 70px;
  font-size: 16px;
  font-weight: 300;
  padding: 8px;
  text-align: left;
}

.one-row-table th {
  border: 1px solid gray;
  padding: 8px;
  text-align: left;
}

.responsive-table {
  width: 100%;
}

.responsive-table th,
.responsive-table td {
  padding: 10px;
}

.responsive-table th {
  text-align: left;
  width: 30%;
  /* Adjust column width as needed */
}

.responsive-table td {
  text-align: left;
}

/* Style for alternating row colors */
.responsive-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.Upload_admin_field {
  border-radius: 4px;

  border-left: 1px solid #dcdfe3;
  border-top: 1px solid #dcdfe3;
  border-bottom: 1px solid #dcdfe3;
}

.Upload_admin_field:has(.Mui-focused) {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid black;
  height: 7px;
}

.bread-crumbs-div li {
  font-family: "Satoshi";
  font-size: 28px;
  font-weight: 500;
  color: rgba(149, 153, 157, 1);
}

.breadcrumbs_navigation > ol > .MuiBreadcrumbs-li:last-child {
  font-weight: 700;
  color: rgba(97, 101, 106, 1);
}

.leaflet-container {
  height: 45vh;
  width: 85%;
  border-radius: 1rem;
}

@media only screen and (max-width: 1365px) {
  .bread-crumbs-div li {
    font-size: 22px;
  }
}

@media only screen and (max-width: 751px) {
  .bread-crumbs-div li {
    font-size: 18px;
  }
}

.otp-input input {
  /* Add your styles here */
  border: 1px solid #717171;
  border-radius: 10px;
  padding: 8px;
  margin: 10px;
}

.otp-input input:focus {
  border-color: blue;
  border: 1px solid red;
}

.otp-input input:active {
  border: 1px solid red;
}

.leaflet-container {
  height: 45vh;
  width: 85%;
  border-radius: 1rem;
}

.jvectormap-region {
  stroke: #ff5566;
  stroke-width: 0.4;
  fill: rgba(244, 244, 244, 0.9);
}

/* .makeStyles-textField-1:focus-within {
  margin-top: -1px;
  border: 1px solid black;
} */

/* Select Field SVG start */
.MuiAutocomplete-endAdornment {
  background-image: url(../src/assets/DownArrow.png);
  width: 14px;
  height: 8px;
  background-repeat: no-repeat;
  margin-top: 11px;
  margin-right: 10px;
}

.MuiAutocomplete-endAdornment button svg {
  display: none;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #f7872c; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav_button {
  border: none;
}
.nav_button:focus {
  outline: none; /* Optional: Remove the default focus outline */
  border-image: linear-gradient(
      to right,
      rgb(255, 159, 10) 0%,
      rgb(255, 55, 95) 62.9%
    )
    1;
}

.nav_button:focus {
  border-width: 2px; /* Set border width as needed */
}
