.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4e4e4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 2.1px;
  background-color: #fff;
  border: 2px solid white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--sec);
}

input:focus + .slider {
  box-shadow: 0 0 1px #eb5757;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.on {
  display: none;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 53%;
  left: 68%;
  font-size: 10px;
  font-weight: 700;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 51%;
  left: 30%;
  font-size: 10px;
  font-weight: 700;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

input:disabled + .slider {
  background-color: #dfdfdf;
}


.slider.round {
  border-radius: 38px;
}

.slider.round:before {
  border-radius: 50%;
}
