.sec-btn:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;

    border-radius: inherit;

    background: linear-gradient(to right, red, orange);
}

.sec-btn {
    background: white;
    display: block;
    border-radius: 12px;
    font-style: normal;
    font-family: satoshi;
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 24px;
    font-weight: 700;
    border: solid 2px transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-clip: padding-box;
    position: relative;
    box-sizing: border-box;
    line-height: 38px;

}

.Active {
    color: red;
}




.bullet-point::before {
    content: '\2022'; /* Unicode for bullet point */
    display: inline-block;
    margin-right: 4px; /* Adjust the spacing between the bullet point and text */

 }
@media screen and (max-width: 768px) {
    :root {
        --small-screen: true;
    }
}


